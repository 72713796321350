import React from 'react';
import { Spin } from 'antd';
import PriceHistoryGraph from './PriceHistoryGraph';
import { get } from '../../helpers/api';

class PriceHistoryLoader extends React.Component {
  state = {};

  componentDidMount() {
    get(`/api/v1/site-sets/${this.props.setId}/prices`).then(({ prices }) => {
      console.log(this.props.setId)
      this.setState({ prices });
    });
  }

  render () {
    return (
      <React.Fragment>
        {this.state.prices ?
          <PriceHistoryGraph prices={this.state.prices} price={this.props.price} /> :
          <Spin />}
      </React.Fragment>
    );
  }
}

export default PriceHistoryLoader;
