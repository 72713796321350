import React from 'react';
import ReactGA from 'react-ga';
import {Button, Input} from 'antd';

export default class TextFilter extends React.Component {
  state = {};

  componentWillReceiveProps(nextProps) {
    if (nextProps.visible && nextProps.visible !== this.props.visible) {
      setTimeout(() => {
        this.searchInput && this.searchInput.focus();
      }, 50);
    }
  }

  onFilterChange = (e) => {
    this.setState({ searchText: e.target.value }, () => {
      this.onSearch(false)();
    });
  }

  onSearch = (onAction) => () => {
    ReactGA.event({
      category: 'Desktop',
      action: 'Filter search',
      label: this.props.filter,
    });
    this.props.onSearch(this.state.searchText, onAction);
  }

  onClear = () => {
    this.setState({ searchText: undefined }, () => {
      this.onSearch(true)();
    });
  }

  render() {
    return (
      <div className="custom-filter-dropdown">
        <Input
          ref={(el) => this.searchInput = el}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.onFilterChange}
          onPressEnter={this.onSearch(true)}
        />
        <Button type="primary" onClick={this.onSearch(true)}>Search</Button>
        <Button style={{ marginLeft: 5 }} onClick={this.onClear}>Clear</Button>
      </div>
    );
  }
}
