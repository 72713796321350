import React from 'react';
import { get } from '../../helpers/api';

class SetStats extends React.Component {
  state = {};

  componentDidMount() {
    get('/api/v1/site-sets/stats').then((stats) => {
      this.setState({ stats });
    });
  }

  render () {
    return (
      <div>
        {this.state.stats ?
          <div>
            Search {this.state.stats.uniques} unique LEGO&reg; sets (of {this.state.stats.sets})
            from {this.state.stats.sites} online Australian stores for the best prices and deals.
          </div> :
          null}
      </div>
    );
  }
}

export default SetStats;