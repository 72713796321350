import React from 'react';
import ReactGA from 'react-ga';
import {Select} from 'antd';

export default class SelectFilter extends React.Component {
  state = {};

  onSelect = (value) => {
    ReactGA.event({
      category: 'Desktop',
      action: 'Select filter option',
      label: this.props.filter,
    });
    this.props.onSelect(value, true);
  }

  render() {
    return (
      <div className="custom-filter-dropdown">
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={this.onSelect}
          value={this.props.value}
          style={{ width: 200 }}
          placeholder={this.props.placeholder}
        >
          {this.props.children}
        </Select>
      </div>
    );
  }
}
