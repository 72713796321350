import React from 'react';
import { get } from '../../helpers/api';

class SiteInfo extends React.Component {
  state = {};

  componentDidMount() {
    const siteId = this.props.siteId;
    if (!siteId) { return; }

    get(`/api/v1/sites/${siteId}`).then((site) => {
      this.setState({ site });
    });
  }

  render () {
    return (
      <div>
        {this.state.site ?
          <div>
            <p dangerouslySetInnerHTML={{__html: this.state.site.ad}} />
          </div> :
          null}
      </div>
    );
  }
}

export default SiteInfo;