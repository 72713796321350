import React from 'react';
import ReactGA from 'react-ga';
import {Icon} from 'antd';

export default class FilterIcon extends React.PureComponent {
  onClick = (event) => {
    ReactGA.event({
      category: 'Desktop',
      action: 'Click filter button',
      label: this.props.filter,
    });
    this.props.onClick(event);
  }

  render() {
    return <Icon
      type="filter"
      style={{ color: this.props.active ? '#108ee9' : '#aaa' }}
      onClick={this.onClick}
    />;
  }
}
