import React from 'react';
import SearchBar from './SearchBar';
import SetStats from './SetStats';
import SetTrending from './SetTrending';

class Search extends React.PureComponent {
  render() {
    return (
      <div>
        <SetStats />
        <SetTrending />
        <SearchBar />
      </div>
    );
  }
}

export default Search;