import React from 'react';

class SetTimes extends React.Component {
  render () {
    const {set, showAdded} = this.props;
    return (
      <div>
        {showAdded ?
          <small>Added: {set.added}</small> :
          <small>Last checked: {set.lastChecked}</small>}
        {(set.lastPriceChanged && set.priceDiff) ?
          <div><small>Price changed: {set.lastPriceChanged}</small></div> :
          null}
      </div>
    );
  }
}

export default SetTimes;