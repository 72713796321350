import moment from 'moment';
import React from 'react';
import { ResponsiveBar } from '@nivo/bar'
import { formatPrice } from '../../helpers/string';

class PriceHistoryGraph extends React.PureComponent {
  render () {
    return (
      <React.Fragment key="price-history">
        <h3>Price History</h3>
        <div style={{ height: 180 }}>
          <ResponsiveBar
            data={this.props.prices}
            indexBy="time"
            keys={['price']}
            margin={{ top: 10, right: 80, bottom: 20, left: 40 }}
            padding={0}
            enableLabel={false}
            // xScale={{ type: 'time', precision: 'day', format: '%Y-%m-%d' }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 3,
              tickRotation: 0,
              legend: 'Price ($)',
              legendPosition: 'middle',
              legendOffset: -32
            }}
            axisBottom={null}
            // axisBottom={{
            //   tickSize: 0,
            //   tickPadding: 3,
            //   tickRotation: -45,
            //   tickValues: 52,
            //   leg1end: 'Price',
            //   lege1ndPosition: 'middle',
            //   legen1dOffset: -32
            // }}
            tooltip={({ indexValue, value }) =>
              <span>{moment(indexValue).format('MMM DD')}: <strong>${formatPrice(value)}</strong></span>
            }
            markers={[
              {
                axis: 'y',
                value: this.props.price,
                lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 1 },
                textStyle: { fill: 'rgba(0, 0, 0, .7)', fontSize: 12 },
                legend: `$${formatPrice(this.props.price)}`,
                legendOrientation: 'horizontal',
                legendPosition: 'right',
              },
            ]}
            labelFormat={(value) => `$${formatPrice(value)}`}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default PriceHistoryGraph;
