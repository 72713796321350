import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { get } from '../../helpers/api';

class SetTrending extends React.Component {
  state = {};

  componentDidMount() {
    get('/api/v1/site-sets/trending').then(({trending}) => {
      this.setState({ trending });
    });
  }

  render () {
    return (
      <div>
        <small>
          <span>Trending: </span>
          {_.map(this.state.trending, ({legoId}) => (
            <span key={legoId}>
              <Link to={`/sets/legoId/${legoId}`}>{legoId}</Link>
              <span> </span>
            </span>
          ))}
        </small>
      </div>
    );
  }
}

export default SetTrending;