import _ from 'lodash';
import React from 'react';
import ReactGA from 'react-ga';
import {Col, Row, Table} from 'antd';
import {Link, withRouter} from 'react-router-dom';
import FilterIcon from '../Filters/FilterIcon';
import LegoSet from './LegoSet';
import PriceHistoryLoader from '../PriceHistory/PriceHistoryLoader';
import SetButtons from './SetButtons';
import SetCarousel from './SetCarousel';
import SetInfo from './SetInfo';
import SetTimes from './SetTimes';
import SiteInfo from './SiteInfo';
import TextFilter from '../Filters/TextFilter';
import { formatPrice} from '../../helpers/string.js';
import { get } from '../../helpers/api';
import { getQueries } from '../../helpers/url';

const {Column} = Table;

class SetResults extends React.Component {
  state = {
    pagination: { current: 1, pageSize: 30 },
  };

  componentDidMount() {
    const query = this.props.query || {};
    const queries = getQueries(this.props.location.search);
    const pagination = { ...this.state.pagination };
    pagination.current = +queries.page || 1;
    this.setState({
      pagination,
      searchName: queries.name,
      searchSite: queries.site,
      sorter: {
        field: queries.orderBy || query.orderBy || this.state.sorter.field,
        order: queries.orderDirection || query.orderDirection || this.state.sorter.order,
      },
    }, () => {
      this.getSets();
    });
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};
    const pagination = { ...this.state.pagination };
    const queries = getQueries(nextProps.location.search);

    if (!_.isEqual(nextProps.query, this.props.query)) {
      const query = nextProps.query || {};
      newState.sorter = {
        field: query.orderBy,
        order: query.orderDirection,
      };
    } else {
      newState.sorter = {
        field: queries.orderBy || this.state.sorter.field,
        order: queries.orderDirection || this.state.sorter.order,
      };
    }

    newState.searchName = queries.name;
    newState.searchSite = queries.site;
    pagination.current = +queries.page || 1;

    newState.pagination = pagination;
    this.setState(newState, () => {
      this.getSets(nextProps.query);
    });
  }

  setSearchUrl = _.debounce(() => {
    const qsList = [
      `page=${this.state.pagination.current}`,
      `orderBy=${this.state.sorter.field}`,
      `orderDirection=${this.state.sorter.order}`,
    ];
    if (this.state.searchName) {
      qsList.push(`name=${encodeURIComponent(this.state.searchName)}`);
    }
    if (this.state.searchSite) {
      qsList.push(`site=${encodeURIComponent(this.state.searchSite)}`);
    }
    window.location = `#${this.props.location.pathname}?${qsList.join('&')}`;
  }, 200)

  getSets = (query = this.props.query) => {
    this.setState({ sets: undefined });

    const qs = _.assign({}, query, {
      page: this.state.pagination.current,
      perPage: this.state.pagination.pageSize,
    });

    if (this.state.searchName) {
      const filter = `name ilike '${this.state.searchName}'`;
      qs.filter = qs.filter ? `${qs.filter} and ${filter}` : filter;
    }
    if (this.state.searchSite) {
      const filter = `site.name ilike '${this.state.searchSite}'`;
      qs.filter = qs.filter ? `${qs.filter} and ${filter}` : filter;
    }

    get('/api/v1/site-sets', qs).then((response) => {
      const pagination = { ...this.state.pagination };
      pagination.total = response.totalCount;
      this.setState({ pagination, sets: response['site-sets']});
    });
  }

  onTableChange = (pagination, filters, sorter) => {
    sorter = _.isEmpty(sorter) ? this.state.sorter : sorter;
    ReactGA.event({
      category: 'Desktop',
      action: 'Results - Change page/sorting',
      label: `sorting-${sorter.field}-${sorter.order}`,
    });
    this.setState({ pagination }, () => {
      this.setSearchUrl();
    });
  }

  onSearch = (prop) => (text, onAction) => {
    const currentText = this.state[`search${prop}`];
    this.setState({ [`search${prop}`]: text, [`filter${prop}DropdownVisible`]: !onAction }, () => {
      if (text !== currentText) {
        this.setSearchUrl();
      }
    });
  }

  render () {
    const {showAdded, showCompareButton, legoId, siteId} = this.props;
    return (
      <div>
        <h2>{this.props.title}</h2>
        <Row>
          <Col span={12}>
            {legoId ? <LegoSet legoId={legoId} /> : null}
            {siteId ? <SiteInfo siteId={siteId} /> : null}
            {this.state.sets ? <p>Found <em>{this.state.pagination.total}</em> results.</p> : null}
          </Col>
          <Col span={12}>
            <SetCarousel legoId={legoId} price={_.get(this.state.sets, '0.price')} />
          </Col>
        </Row>
        <Table
          dataSource={this.state.sets}
          pagination={this.state.pagination}
          loading={!this.state.sets}
          onChange={this.onTableChange}
          rowKey="id"
          size="small"
          expandedRowRender={(set) => <PriceHistoryLoader price={set.price} setId={set.id} />}
        >
          <Column
            title="Store"
            key="siteId"
            width={250}
            render={(text, set) => (
              <div>
                {set.siteLogo ? <div dangerouslySetInnerHTML={{__html: set.siteLogo}} /> : null}
                <Link to={`/sets/siteId/${set.siteId}`} alt={`Sets for ${set.siteName}`}>{set.siteName}</Link>&nbsp;
                <a
                  alt={set.siteName}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={set.siteUrl}
                >
                  <i className="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </div>
            )}
            filterIcon={<FilterIcon active={!!this.state.searchSite} filter="site" />}
            filterDropdown={
              <TextFilter
                visible={this.state.filterSiteDropdownVisible}
                filter="site"
                placeholder="Search name..."
                value={this.state.searchSite}
                onSearch={this.onSearch('Site')}
              />
            }
            filterDropdownVisible={this.state.filterSiteDropdownVisible}
            onFilterDropdownVisibleChange={(visible) => {
              this.setState({ filterSiteDropdownVisible: visible });
            }}
          />
          <Column
            title="ID"
            dataIndex="legoId"
            key="legoId"
            width={100}
          />
          <Column
            title="Set"
            key="set"
            render={(text, set) => (
              <div>
                <div>{set.name}</div>
                <div><SetTimes {...{set, showAdded}} /></div>
              </div>
            )}
            filterIcon={<FilterIcon active={!!this.state.searchName} filter="name" />}
            filterDropdown={
              <TextFilter
                visible={this.state.filterNameDropdownVisible}
                placeholder="Search name..."
                filter="name"
                value={this.state.searchName}
                onSearch={this.onSearch('Name')}
              />
            }
            filterDropdownVisible={this.state.filterNameDropdownVisible}
            onFilterDropdownVisibleChange={(visible) => {
              this.setState({ filterNameDropdownVisible: visible });
            }}
          />
          <Column
            title="Price (AUD)"
            key="price"
            width={150}
            render={(text, set) => <SetInfo set={set} />}
          />
          <Column
            title="Stock?"
            key="inStock"
            width={120}
            render={(text, set) => (
              <React.Fragment>
                <div>
                  {set.inStock ?
                    <i className="fa fa-check" aria-hidden="true" style={{color: 'green'}}></i> :
                    <i className="fa fa-times" aria-hidden="true" style={{color: 'red'}}></i>}
                </div>
                <div>
                  <small>
                    <span style={{color: 'green'}}>${formatPrice(set.minPrice)}</span> -&nbsp;
                    <span style={{color: 'red'}}>${formatPrice(set.maxPrice)}</span>
                  </small>
                </div>
              </React.Fragment>
            )}
          />
          <Column
            title=""
            key="action"
            width={182}
            render={(text, set) => <SetButtons {...{set, showCompareButton}} />}
          />
        </Table>
      </div>
    );
  }
}

export default withRouter(SetResults);
