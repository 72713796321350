import _ from 'lodash';
import React from 'react';
import ReactGA from 'react-ga';
import {Button, Table} from 'antd';
import {Link, withRouter} from 'react-router-dom';
import FilterIcon from '../Filters/FilterIcon';
import TextFilter from '../Filters/TextFilter';
import { get } from '../../helpers/api';
import { getQueries } from '../../helpers/url';

const {Column} = Table;

class SiteBrowse extends React.Component {
  state = {
    pagination: { current: 1, pageSize: 30 },
    sorter: { field: 'name', order: 'ascend' }
  };

  componentDidMount() {
    const queries = getQueries(this.props.location.search);
    const pagination = { ...this.state.pagination };
    pagination.current = +queries.page || 1;
    this.setState({
      pagination,
      searchText: queries.name,
      sorter: {
        field: queries.orderBy || this.state.sorter.field,
        order: queries.orderDirection || this.state.sorter.order,
      },
    }, () => {
      this.getSites();
    });
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};
    const pagination = { ...this.state.pagination };
    const queries = getQueries(nextProps.location.search);

    newState.sorter = {
      field: queries.orderBy || this.state.sorter.field,
      order: queries.orderDirection || this.state.sorter.order,
    }
    newState.searchText = queries.name;
    pagination.current = +queries.page || 1;

    newState.pagination = pagination;
    this.setState(newState, () => {
      this.getSites();
    });
  }

  setSearchUrl = _.debounce(() => {
    const qsList = [
      `page=${this.state.pagination.current}`,
      `orderBy=${this.state.sorter.field}`,
      `orderDirection=${this.state.sorter.order}`,
    ];
    if (this.state.searchText) {
      qsList.push(`name=${encodeURIComponent(this.state.searchText)}`);
    }
    window.location = `#${this.props.location.pathname}?${qsList.join('&')}`;
  }, 150)

  getSites = () => {
    this.setState({ sites: undefined });

    let filter = 'setCount > 0';
    if (this.state.searchText) {
      filter = `${filter} and name ilike '${this.state.searchText}'`;
    }

    const qs = {
      orderBy: this.state.sorter.field,
      orderDirection: this.state.sorter.order === 'ascend' ? 'asc' : 'desc',
      filter,
      page: this.state.pagination.current,
      perPage: this.state.pagination.pageSize,
    };

    get('/api/v1/sites', qs).then(this.updateSites);
  }

  updateSites = ({ sites, totalCount }) => {
    const pagination = { ...this.state.pagination };
    pagination.total = totalCount;
    this.setState({ sites, pagination });
  }

  onTableChange = (pagination, filters, sorter) => {
    sorter = _.isEmpty(sorter) ? this.state.sorter : sorter;
    ReactGA.event({
      category: 'Desktop',
      action: 'Sites - Change page/sorting',
      label: `sorting-${sorter.field}-${sorter.order}`,
    });
    this.setState({ pagination, sorter }, () => {
      this.setSearchUrl();
    });
  }

  onSearch = (text, onAction) => {
    const currentText = this.state.searchText;
    this.setState({ searchText: text, filterDropdownVisible: !onAction }, () => {
      if (text !== currentText) {
        this.setSearchUrl();
      }
    });
  }

  render () {
    return (
      <div>
        <h2>Browse sites</h2>
        <div>
          {this.state.sites ? <p>Found <em>{this.state.pagination.total}</em> results.</p> : null}
          <Table
            dataSource={this.state.sites}
            pagination={this.state.pagination}
            loading={!this.state.sites}
            onChange={this.onTableChange}
            rowKey="id"
            size="small"
          >
            <Column
              title="Name"
              key="name"
              dataIndex="name"
              sorter
              sortOrder={this.state.sorter.field === 'name' ? this.state.sorter.order : false}
              render={(text, site) => (
                <div>
                  <Link to={`/sets/siteId/${site.id}`} alt={`Sets for ${site.name}`}>{site.name}</Link>&nbsp;
                  <a
                    alt={site.name}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={site.url}
                  >
                    <i className="fa fa-external-link" aria-hidden="true"></i>
                  </a>
                </div>
              )}
              filterIcon={<FilterIcon active={!!this.state.searchText} filter="name" />}
              filterDropdown={
                <TextFilter
                  visible={this.state.filterDropdownVisible}
                  placeholder="Search name..."
                  filter="name"
                  value={this.state.searchText}
                  onSearch={this.onSearch}
                />
              }
              filterDropdownVisible={this.state.filterDropdownVisible}
              onFilterDropdownVisibleChange={(visible) => {
                this.setState({ filterDropdownVisible: visible });
              }}
            />
            <Column
              title="Sets"
              dataIndex="setCount"
              key="setCount"
              width={120}
              sorter
              sortOrder={this.state.sorter.field === 'setCount' ? this.state.sorter.order : false}
            />
            <Column
              title="Last Checked"
              dataIndex="lastChecked"
              key="lastChecked"
              width={200}
            />
            <Column
              title=""
              key="action"
              width={150}
              render={(text, site) => (
                <Button
                  href={`#/sets/siteId/${site.id}`}
                  alt={`View sets for #${site.name}`}
                >
                  View Sets
                </Button>
              )}
            />
          </Table>
        </div>
      </div>
    );
  }
}

export default withRouter(SiteBrowse);
