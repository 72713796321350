import React from 'react';
import { get } from '../../helpers/api';
import { formatPrice } from '../../helpers/string.js';

class LegoSet extends React.Component {
  state = {};

  componentDidMount() {
    this.getLegoSet(this.props.legoId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.legoId !== this.props.legoId) {
      this.getLegoSet(nextProps.legoId);
    }
  }

  getLegoSet = (legoId) => {
    this.setState({ set: undefined });
    get(`/api/v1/sets/${legoId}`).then((set) => {
      this.setState({ set });
    });
  }

  render () {
    return (
      <div>
        {this.state.set ?
          <p>
            {`${this.state.set.name}`} ({`${this.state.set.year}`}) of
            <i> {`${this.state.set.theme}`} </i> theme
            {this.state.set.pieces && <span> with {`${this.state.set.pieces}`} pieces</span>}.
            Historical price range of <span style={{color: 'green'}}>${formatPrice(this.state.set.minHistoricalPrice)} </span>
            to <span style={{color: 'red'}}>${formatPrice(this.state.set.maxHistoricalPrice)}</span>.
          </p> :
          null}
      </div>
    );
  }
}

export default LegoSet;
