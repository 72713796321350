export const getQueries = (search) => {
  const queries = {};
  if (!search) { return queries; }

  const queryString = search.split('?')[1];
  if (!queryString) { return queries; }

  const keyValueStrings = queryString.split('&');
  keyValueStrings.forEach((keyValueString) => {
    const [key, value] = keyValueString.split('=');
    queries[key] = decodeURIComponent(value);
  });

  return queries;
};