import React from 'react';
import {Icon, Tooltip} from 'antd';
import {formatPercent, formatPrice} from '../../helpers/string.js';

class SetInfo extends React.Component {
  render () {
    const {set} = this.props;
    return (
      <React.Fragment>
        <div>${formatPrice(set.price)}&nbsp;</div>
        <div>
          <small>
            +${formatPrice(set.adjustedShippingFee)}&nbsp;
            <Tooltip title={set.shippingNotes}>
              <Icon type="mail" theme="twoTone" />
            </Tooltip>
          </small>
        </div>
        {set.priceDiff ?
          <div>
            <small>
              {set.priceDiff > 0 ?
                <i className="fa fa-caret-up" aria-hidden="true" style={{color: 'green'}}></i> :
                <i className="fa fa-caret-down" aria-hidden="true" style={{color: 'red'}}></i>}
              &nbsp;${formatPrice(Math.abs(set.priceDiff))} ({formatPercent(Math.abs(set.priceDiff / (set.price - set.priceDiff) * 100))})
            </small>
          </div> :
          null}
      </React.Fragment>
    );
  }
}

export default SetInfo;
