import _ from 'lodash';
import React from 'react';
import ReactGA from 'react-ga';
import {Button, Rate, Table} from 'antd';
import {withRouter} from 'react-router-dom';
import FilterIcon from '../Filters/FilterIcon';
import TextFilter from '../Filters/TextFilter';
import { get } from '../../helpers/api';
import { getQueries } from '../../helpers/url';
import { stripHtml } from '../../helpers/string';

const {Column} = Table;

class ReviewBrowse extends React.Component {
  state = {
    pagination: { current: 1, pageSize: 30 },
    sorter: { field: 'createdAt', order: 'descend' }
  };

  componentDidMount() {
    const queries = getQueries(this.props.location.search);
    const pagination = { ...this.state.pagination };
    pagination.current = +queries.page || 1;
    this.setState({
      pagination,
      searchSite: queries.name,
      searchId: queries.legoId,
      sorter: {
        field: queries.orderBy || this.state.sorter.field,
        order: queries.orderDirection || this.state.sorter.order,
      },
    }, () => {
      this.getReviews();
    });
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};
    const pagination = { ...this.state.pagination };
    const queries = getQueries(nextProps.location.search);

    newState.sorter = {
      field: queries.orderBy || this.state.sorter.field,
      order: queries.orderDirection || this.state.sorter.order,
    }
    newState.searchSite = queries.name;
    pagination.current = +queries.page || 1;

    newState.pagination = pagination;
    this.setState(newState, () => {
      this.getReviews();
    });
  }

  setSearchUrl = _.debounce(() => {
    const qsList = [
      `page=${this.state.pagination.current}`,
      `orderBy=${this.state.sorter.field}`,
      `orderDirection=${this.state.sorter.order}`,
    ];
    if (this.state.searchSite) {
      qsList.push(`name=${encodeURIComponent(this.state.searchSite)}`);
    }
    if (this.state.searchId) {
      qsList.push(`legoId=${this.state.searchId}`);
    }
    window.location = `#${this.props.location.pathname}?${qsList.join('&')}`;
  }, 150)

  getReviews = () => {
    this.setState({ reviews: undefined });

    const qs = _.assign({}, {
      page: this.state.pagination.current,
      perPage: this.state.pagination.pageSize,
    });

    if (this.state.searchSite) {
      const filter = `site.name ilike '${this.state.searchSite}'`;
      qs.filter = qs.filter ? `${qs.filter} and ${filter}` : filter;
    }
    if (this.state.searchId) {
      const filter = `legoId startWith '${this.state.searchId}'`;
      qs.filter = qs.filter ? `${qs.filter} and ${filter}` : filter;
    }

    get('/api/v1/reviews', qs).then(this.updateReviews);
  }

  updateReviews = ({ reviews, totalCount }) => {
    const pagination = { ...this.state.pagination };
    pagination.total = totalCount;
    this.setState({ reviews, pagination });
  }

  onTableChange = (pagination, filters, sorter) => {
    sorter = _.isEmpty(sorter) ? this.state.sorter : sorter;
    ReactGA.event({
      category: 'Desktop',
      action: 'Reviews - Change page/sorting',
      label: `sorting-${sorter.field}-${sorter.order}`,
    });
    this.setState({ pagination, sorter }, () => {
      this.setSearchUrl();
    });
  }

  onSearch = (prop) => (text, onAction) => {
    const currentText = this.state[`search${prop}`];
    this.setState({ [`search${prop}`]: text, [`filter${prop}DropdownVisible`]: !onAction }, () => {
      if (text !== currentText) {
        this.setSearchUrl();
      }
    });
  }

  render () {
    return (
      <div>
        <h2>Browse reviews</h2>
        <div>
          {this.state.reviews ? <p>Found <em>{this.state.pagination.total}</em> results.</p> : null}
          <Table
            dataSource={this.state.reviews}
            pagination={this.state.pagination}
            loading={!this.state.reviews}
            onChange={this.onTableChange}
            rowKey="id"
            size="small"
          >
            <Column
              title="Site"
              key="siteId"
              dataIndex="siteId"
              width={250}
              render={(text, review) => (
                <div>
                  <a
                    alt={review.siteName}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={review.siteUrl}
                  >
                    {review.siteName}&nbsp;
                    <i className="fa fa-external-link" aria-hidden="true"></i>
                  </a>
                </div>
              )}
              filterIcon={<FilterIcon active={!!this.state.searchSite} filter="site" />}
              filterDropdown={
                <TextFilter
                  visible={this.state.filterSiteDropdownVisible}
                  placeholder="Search site..."
                  filter="site"
                  value={this.state.searchSite}
                  onSearch={this.onSearch('Site')}
                />
              }
              filterDropdownVisible={this.state.filterSiteDropdownVisible}
              onFilterDropdownVisibleChange={(visible) => {
                this.setState({ filterSiteDropdownVisible: visible });
              }}
            />
            <Column
              title="ID"
              dataIndex="legoId"
              key="legoId"
              width={100}
              filterIcon={<FilterIcon active={!!this.state.searchId} filter="legoId" />}
              filterDropdown={
                <TextFilter
                  visible={this.state.filterIdDropdownVisible}
                  placeholder="Search ID..."
                  filter="legoId"
                  value={this.state.searchId}
                  onSearch={this.onSearch('Id')}
                />
              }
              filterDropdownVisible={this.state.filterIdDropdownVisible}
              onFilterDropdownVisibleChange={(visible) => {
                this.setState({ filterIdDropdownVisible: visible });
              }}
            />
            <Column
              title="Review"
              dataIndex="highlight"
              key="highlight"
              render={(text, review) => (
                <div>
                  <h4><a target="_blank" rel="noopener noreferrer" href={review.url}>{review.title}</a></h4>
                  <span style={{wordBreak: 'normal'}}>
                    {stripHtml(review.highlight).replace(/\.\.\.(.*)Read More(.*)$/, '')}…
                    <a target="_blank" rel="noopener noreferrer" href={review.url}>(more)</a>
                  </span>
                </div>
              )}
            />
            <Column
              title="Rating"
              dataIndex="rating"
              key="rating"
              width={200}
              render={(text, review) => (
                review.rating ? <Rate allowHalf disabled defaultValue={review.rating} /> : null
              )}
            />
            <Column
              title=""
              key="action"
              width={180}
              render={(text, review) => (
                <Button
                  href={`#/sets/legoId/${review.legoId}`}
                  alt={`Compare pricing for set #${review.legoId}`}
                >
                  Compare Pricing
                </Button>
              )}
            />
          </Table>
        </div>
      </div>
    );
  }
}

export default withRouter(ReviewBrowse);
