import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Carousel, List, Rate } from 'antd';
import PriceHistoryGraph from '../PriceHistory/PriceHistoryGraph';
import { get } from '../../helpers/api';
import { formatPrice, stripHtml } from '../../helpers/string';

class SetCarousel extends React.Component {
  state = {};

  componentDidMount() {
    this.getReviews(this.props.legoId);
    this.getAccessories(this.props.legoId);
    this.getPriceHistory(this.props.legoId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.legoId !== this.props.legoId) {
      this.getReviews(nextProps.legoId);
      this.getAccessories(nextProps.legoId);
      this.getPriceHistory(nextProps.legoId);
    }
  }

  getReviews = (legoId) => {
    this.setState({ reviews: undefined });
    get(`/api/v1/reviews?filter=legoId eq ${legoId}`).then(({reviews}) => {
      this.setState({ reviews });
    });
  }

  getAccessories = (legoId) => {
    this.setState({ accessories: undefined });
    get(`/api/v1/accessories?filter=legoId eq ${legoId}`).then(({accessories}) => {
      this.setState({ accessories });
    });
  }

  getPriceHistory = (legoId) => {
    this.setState({ prices: undefined });
    get(`/api/v1/sets/${legoId}/prices`).then(({prices}) => {
      this.setState({ prices: _.map(prices, (price) => {
        price.time = moment(price.time).format('YYY-MM-DD');
        return price;
      }) });
    });
  }

  renderItems = () => {
    const items = _.compact(_.concat(this.state.reviews, this.state.accessories));
    const carouselItems = _.map(items, (item) =>
      <List
        key={item.id}
        itemLayout="vertical"
        size="small"
        pagination={false}
        dataSource={[item]}
        renderItem={(item) => (
          item.title ?
            <List.Item
              key={item.id}
              style={{paddingRight: 5}}
              actions={_.compact([
                <span>
                  Review from&nbsp;
                  <a target="_blank" rel="noopener noreferrer" href={item.siteUrl}>
                    {item.siteName}&nbsp;
                    <i className="fa fa-external-link" aria-hidden="true"></i>
                  </a>
                </span>,
                item.rating ? <Rate allowHalf disabled defaultValue={item.rating} /> : null,
              ])}
            >
              <List.Item.Meta
                title={<a target="_blank" rel="noopener noreferrer" href={item.url}>{item.title}</a>}
                description={
                  <span>
                    {stripHtml(item.highlight).replace(/\.\.\.(.*)Read More(.*)$/, '')}…
                    <a target="_blank" rel="noopener noreferrer" href={item.url}>(more)</a>
                  </span>
                }
              />
            </List.Item> :
            <List.Item
              key={item.id}
              style={{paddingRight: 5}}
              extra={<img width={150} alt={item.name} src={item.image} />}
              actions={[
                <span>
                  Accessory from&nbsp;
                  <a target="_blank" rel="noopener noreferrer" href={item.siteUrl}>
                    {item.siteName}&nbsp;
                    <i className="fa fa-external-link" aria-hidden="true"></i>
                  </a>
                </span>,
              ]}
            >
              <List.Item.Meta
                title={<a target="_blank" rel="noopener noreferrer" href={item.url}>{item.name}</a>}
                description={<span>Price: ${formatPrice(item.price)}</span>}
              />
            </List.Item>
        )}
      />
    );

    if (!_.isEmpty(this.state.prices)) {
      carouselItems.push(<PriceHistoryGraph key="price-history" price={this.props.price} prices={this.state.prices || []} />);
    }

    return carouselItems;
  }


  render () {
    const items = _.compact(_.concat(this.state.reviews, this.state.accessories));
    return (
      !_.isEmpty(items) || !_.isEmpty(this.state.prices) ?
        <div style={{marginBottom: 10, padding: '0 10px', backgroundColor: '#eee' }}>
          <Carousel
            autoplay
            dots
          >
            {this.renderItems()}
          </Carousel>
        </div>
    : null);
  }
}

export default SetCarousel;
