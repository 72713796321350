import React from 'react';
import { BackTop, Col, Divider, Layout, Row } from 'antd';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Analytics from './components/Analytics';
import Header from './components/Layout/Header';
import ReviewBrowse from './components/ReviewBrowse';
import Search from './components/Search';
import SetBrowse from './components/SetBrowse';
import SetResults from './components/SetResults';
import SiteBrowse from './components/SiteBrowse';
import './App.css';

const { Content, Footer } = Layout;

class App extends React.Component {
  componentDidMount() {
    const matches = /^\?(legoId|siteId)=(\d+)/.exec(window.location.search);
    if (matches) {
      window.location.href = `/#/sets/${matches[1]}/${matches[2]}`;
    }
    else if (isMobile) {
      window.location.href = `https://m.brickcompare.com/${window.location.hash}`;
    }
  }

  render() {
    return (
      <HashRouter>
        <Layout>
          <Analytics />
          <BackTop />
          <Header />
          <Content className="content">
            <Search />
            <Divider />
            <Switch>
              <Route path="/stores" exact component={SiteBrowse} />
              <Route path="/reviews" exact component={ReviewBrowse} />
              <Route path="/sets" exact render={({match, location}) => (
                <SetBrowse
                  query={{
                    orderBy: 'legoId',
                    orderDirection: 'descend',
                  }}
                  title="Browse LEGO&reg; sets"
                />
              )} />
              <Route path="/sets/deals" exact render={({match, location}) => (
                <SetBrowse
                  query={{
                    orderBy: 'percentPriceDiff',
                    orderDirection: 'descend',
                    filter: 'inStock eq 1 and isBundle eq 0',
                  }}
                  title="Browse LEGO&reg; sets with biggest price differences"
                  disableSorting
                />
              )} />
              <Route path="/sets/changes" exact render={({match, location}) => (
                <SetResults
                  query={{
                    orderBy: 'priceChangedAt',
                    orderDirection: 'desc',
                    filter: 'hasPriceChange eq 1'
                  }}
                  title="Recent LEGO&reg; set price changes"
                  showCompareButton
                />
              )} />
              <Route path="/sets/added" exact render={({match, location}) => (
                <SetResults
                  query={{
                    orderBy: 'createdAt',
                    orderDirection: 'desc',
                  }}
                  title="Newly added LEGO&reg; sets"
                  showAdded
                  showCompareButton
                />
              )} />
              <Route children={({match, location}) => (
                <Route path="/sets/:type/:id" children={({match, location}) => {
                  const legoId = match && match.params.type === 'legoId' && match.params.id;
                  const siteId = match && match.params.type === 'siteId' && match.params.id;
                  return (
                    <div>
                      {legoId ?
                        <SetResults
                          query={{
                            orderBy: 'price',
                            orderDirection: 'asc',
                            filter: `legoId eq ${legoId}`,
                          }}
                          title={`Results for LEGO® set #${legoId}`}
                          legoId={legoId}
                        /> : null}
                      {siteId ?
                        <SetResults
                          query={{
                            orderBy: 'price',
                            orderDirection: 'asc',
                            filter: `siteId eq ${siteId}`,
                          }}
                          title="Results for store"
                          siteId={siteId}
                          showCompareButton
                        /> : null}
                    </div>
                  );
                }}/>
              )}/>
            </Switch>
          </Content>
          <Footer className="footer">
            <Row>
              <Col span={18}>
                <p>
                  <a href="mailto:contact@brickcompare.com" alt="Contact">Contact</a>
                </p>
                <small>LEGO and the LEGO logo are trade marks of the LEGO Group. &copy;{new Date().getFullYear()} The LEGO Group.</small>
              </Col>
              <Col span={6} style={{textAlign: 'right'}}>
                <a rel="nofollow" href="https://affiliate.namecheap.com/?affId=47043">
                  <img src="https://files.namecheap.com/graphics/linkus/234x60-2.gif" width="234" height="60" border="0" alt="Namecheap.com" />
                </a>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </HashRouter>
    );
  }
}

export default App;
