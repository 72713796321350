import _ from 'lodash';
import React from 'react';
import ReactGA from 'react-ga';
import {Button, Select, Table} from 'antd';
import { withRouter } from 'react-router-dom';
import FilterIcon from '../Filters/FilterIcon';
import SelectFilter from '../Filters/SelectFilter';
import TextFilter from '../Filters/TextFilter';
import { formatPercent, formatPrice } from '../../helpers/string';
import { get } from '../../helpers/api';
import { getQueries } from '../../helpers/url';


const {Column} = Table;

class Browse extends React.Component {
  state = {
    pagination: { current: 1, pageSize: 30 },
    sorter: { field: 'legoId', order: 'descend' },
  };

  componentDidMount() {
    const query = this.props.query || {};
    const queries = getQueries(this.props.location.search);
    const pagination = { ...this.state.pagination };
    pagination.current = +queries.page || 1;
    this.setState({
      pagination,
      searchName: queries.name,
      searchTheme: queries.theme,
      searchYear: queries.year,
      sorter: {
        field: queries.orderBy || query.orderBy || this.state.sorter.field,
        order: queries.orderDirection || query.orderDirection || this.state.sorter.order,
      },
    }, () => {
      this.getSets();
    });
    get('/api/v1/themes', {perPage: 'all', orderDirection: 'asc'}).then(({ themes }) => {
      this.setState({ themes });
    });
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};
    const pagination = { ...this.state.pagination };
    const queries = getQueries(nextProps.location.search);

    if (!_.isEqual(nextProps.query, this.props.query)) {
      const query = nextProps.query || {};
      newState.sorter = {
        field: query.orderBy,
        order: query.orderDirection,
      };
    } else {
      newState.sorter = {
        field: queries.orderBy || this.state.sorter.field,
        order: queries.orderDirection || this.state.sorter.order,
      };
    }

    newState.searchName = queries.name;
    newState.searchTheme = queries.theme;
    newState.searchYear = queries.year;
    pagination.current = +queries.page || 1;

    newState.pagination = pagination;
    this.setState(newState, () => {
      this.getSets(nextProps.query);
    });
  }

  setSearchUrl = _.debounce(() => {
    const qsList = [
      `page=${this.state.pagination.current}`,
      `orderBy=${this.state.sorter.field}`,
      `orderDirection=${this.state.sorter.order}`,
    ];
    if (this.state.searchName) {
      qsList.push(`name=${encodeURIComponent(this.state.searchName)}`);
    }
    if (this.state.searchYear) {
      qsList.push(`year=${this.state.searchYear}`);
    }
    if (this.state.searchTheme) {
      qsList.push(`theme=${encodeURIComponent(this.state.searchTheme)}`);
    }
    window.location = `#${this.props.location.pathname}?${qsList.join('&')}`;
  }, 150)

  getSets = (query = this.props.query) => {
    this.setState({ sets: undefined });

    const qs = _.assign({}, query, {
      orderBy: this.state.sorter.field,
      orderDirection: this.state.sorter.order === 'ascend' ? 'asc' : 'desc',
      page: this.state.pagination.current,
      perPage: this.state.pagination.pageSize,
    });

    if (this.state.searchName) {
      const nameFilter = `name ilike '${this.state.searchName}'`;
      qs.filter = qs.filter ? `${qs.filter} and ${nameFilter}` : nameFilter;
    }
    if (this.state.searchYear) {
      const yearFilter = `year eq '${this.state.searchYear}'`;
      qs.filter = qs.filter ? `${qs.filter} and ${yearFilter}` : yearFilter;
    }
    if (this.state.searchTheme) {
      const themeFilter = `theme eq '${this.state.searchTheme}'`;
      qs.filter = qs.filter ? `${qs.filter} and ${themeFilter}` : themeFilter;
    }

    get('/api/v1/sets', qs).then(this.updateSets);
  }

  updateSets = ({ sets, totalCount }) => {
    const pagination = { ...this.state.pagination };
    pagination.total = totalCount;
    this.setState({ sets, pagination });
  }

  onTableChange = (pagination, filters, sorter) => {
    sorter = _.isEmpty(sorter) ? this.state.sorter : sorter;
    ReactGA.event({
      category: 'Desktop',
      action: 'Browse - Change page/sorting',
      label: `sorting-${sorter.field}-${sorter.order}`,
    });
    this.setState({ pagination, sorter }, () => {
      this.setSearchUrl();
    });
  }

  onSearch = (prop) => (text, onAction) => {
    const currentText = this.state[`search${prop}`];
    this.setState({ [`search${prop}`]: text, [`filter${prop}DropdownVisible`]: !onAction }, () => {
      if (text !== currentText) {
        this.setSearchUrl();
      }
    });
  }

  render () {
    return (
      <div>
        <h2>{this.props.title}</h2>
        <div>
          {this.state.sets ? <p>Found <em>{this.state.pagination.total}</em> results.</p> : null}
          <Table
            dataSource={this.state.sets}
            pagination={this.state.pagination}
            loading={!this.state.sets}
            onChange={this.onTableChange}
            rowKey="id"
            size="small"
          >
            <Column
              title="ID"
              dataIndex="legoId"
              key="legoId"
              width={100}
              sorter={!this.props.disableSorting}
              sortOrder={this.state.sorter.field === 'legoId' ? this.state.sorter.order : false}
            />
            <Column
              title="Set"
              key="name"
              dataIndex="name"
              sorter={!this.props.disableSorting}
              sortOrder={this.state.sorter.field === 'name' ? this.state.sorter.order : false}
              filterIcon={<FilterIcon active={!!this.state.searchName} filter="name" />}
              filterDropdown={
                <TextFilter
                  visible={this.state.filterNameDropdownVisible}
                  filter="name"
                  value={this.state.searchName}
                  placeholder="Search name..."
                  onSearch={this.onSearch('Name')}
                />
              }
              filterDropdownVisible={this.state.filterNameDropdownVisible}
              onFilterDropdownVisibleChange={(visible) => {
                this.setState({ filterNameDropdownVisible: visible });
              }}
            />
            <Column
              title="Theme"
              dataIndex="theme"
              key="theme"
              width={250}
              filterIcon={<FilterIcon active={!!this.state.searchTheme} filter="theme" />}
              filterDropdown={
                <SelectFilter
                  visible={this.state.filterThemeDropdownVisible}
                  placeholder="Select theme..."
                  filter="theme"
                  value={this.state.searchTheme}
                  onSelect={this.onSearch('Theme')}
                >
                  {_.map(this.state.themes, ({sets, theme}) =>
                    <Select.Option key={theme} value={theme}>{`${theme} (${sets})`}</Select.Option>
                  )}
                </SelectFilter>
              }
              filterDropdownVisible={this.state.filterThemeDropdownVisible}
              onFilterDropdownVisibleChange={(visible) => {
                this.setState({ filterThemeDropdownVisible: visible });
              }}
            />
            <Column
              title="Year"
              dataIndex="year"
              key="year"
              width={120}
              sorter={!this.props.disableSorting}
              sortOrder={this.state.sorter.field === 'year' ? this.state.sorter.order : false}
              filterIcon={<FilterIcon active={!!this.state.searchYear} filter="year" />}
              filterDropdown={
                <TextFilter
                  visible={this.state.filterYearDropdownVisible}
                  placeholder="Search year..."
                  filter="year"
                  value={this.state.searchYear}
                  onSearch={this.onSearch('Year')}
                />
              }
              filterDropdownVisible={this.state.filterYearDropdownVisible}
              onFilterDropdownVisibleChange={(visible) => {
                this.setState({ filterYearDropdownVisible: visible });
              }}
            />
            <Column
              title="Stores"
              dataIndex="siteCount"
              key="siteCount"
              sorter={!this.props.disableSorting}
              sortOrder={this.state.sorter.field === 'siteCount' ? this.state.sorter.order : false}
              width={100}
            />
            <Column
              title="Price (AUD)"
              key="price"
              dataIndex="price"
              sortOrder={this.state.sorter.field === 'price' ? this.state.sorter.order : false}
              sorter={!this.props.disableSorting}
              width={200}
              render={(text, set) => (
                <div>
                  ${formatPrice(set.price)}
                  {(set.maxPrice !== set.price) ?
                    <span> <i className="fa fa-minus" aria-hidden="true"></i> ${formatPrice(set.maxPrice)}</span> : null}
                </div>
              )}
            />
            <Column
              title="Diff (%)"
              dataIndex="percentPriceDiff"
              key="percentPriceDiff"
              sorter={!this.props.disableSorting}
              width={100}
              render={(text, set) => (<span>{formatPercent(set.percentPriceDiff)}</span>)}
              sortOrder={this.state.sorter.field === 'percentPriceDiff' ? this.state.sorter.order : false}
            />
            <Column
              title=""
              key="action"
              width={120}
              render={(text, set) => (
                <Button
                  href={`#/sets/legoId/${set.legoId}`}
                  alt={`Compare prices for set #${set.legoId}`}
                >
                  Compare
                </Button>
              )}
            />
          </Table>
        </div>
      </div>
    );
  }
}

export default withRouter(Browse);