import React from 'react';
import { Layout, Menu } from 'antd';
import { Link, matchPath , withRouter } from 'react-router-dom';

class Header extends React.Component {
  render() {
    let activeKey = 'home';

    const sectionMatch = matchPath(this.props.location.pathname, { path: '/:section', exact: true });
    const setsMatch = matchPath(this.props.location.pathname, { path: '/sets/:type', exact: false });
    if (sectionMatch && sectionMatch.params.section) {
      activeKey = sectionMatch.params.section;
    }
    if (setsMatch && setsMatch.params.type !== 'legoId' && setsMatch.params.type !== 'siteId') {
      activeKey = setsMatch.params.type;
    }

    return (
      <Layout.Header className="header">
        <h1 className="title" style={{ fontWeight: 600}}>
          <img
            alt="BrickCompare"
            src="/images/logo.png"
            style={{width: 37, height: 37, marginRight: 5}}
          />
          BRICK<span style={{ fontWeight: 400 }}>COMPARE</span>
        </h1>
        <Menu
          theme="dark"
          mode="horizontal"
          triggerSubMenuAction="click"
          selectedKeys={[activeKey]}
          style={{ lineHeight: '64px' }}
        >
          <Menu.Item key="home">
            <a href="#/"><i className="fa fa-home" aria-hidden="true"></i> Home</a>
          </Menu.Item>
          <Menu.SubMenu
            title={
              <span>
                <i className="fa fa-gift" aria-hidden="true"></i> Sets <i className="fa fa-angle-down" aria-hidden="true"></i>
              </span>
            }
          >
            <Menu.Item key="sets">
              <Link to="/sets"><i className="fa fa-th-list" aria-hidden="true"></i> Browse</Link>
            </Menu.Item>
            <Menu.Item key="deals">
              <Link to="/sets/deals"><i className="fa fa-usd" aria-hidden="true"></i> Best Deals</Link>
            </Menu.Item>
            <Menu.Item key="changes">
              <Link to="/sets/changes"><i className="fa fa-line-chart" aria-hidden="true"></i> Price Changes</Link>
            </Menu.Item>
            <Menu.Item key="added">
              <Link to="/sets/added"><i className="fa fa-plus-square" aria-hidden="true"></i> Newly Added</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="stores">
            <Link to="/stores"><i className="fa fa-shopping-bag" aria-hidden="true"></i> Stores</Link>
          </Menu.Item>
          <Menu.Item key="reviews">
            <Link to="/reviews"><i className="fa fa-commenting" aria-hidden="true"></i> Reviews</Link>
          </Menu.Item>
        </Menu>
      </Layout.Header>
    );
  }
}

export default withRouter(Header);
