import React from 'react';
import ReactGA from 'react-ga';
import {Button} from 'antd';

class SetButtons extends React.Component {
  navToExternal = (set) => {
    ReactGA.event({
      category: 'Desktop',
      action: 'Click buy button',
      label: `${set.siteId}-${set.legoId}`,
      value: set.hasAffiliate,
    });
    window.gtag_report_conversion();
  }

  render () {
    const { set, showCompareButton } = this.props;
    return (
      <div>
        {showCompareButton ?
          <Button
            style={{marginRight: '5px'}}
            href={`#/sets/legoId/${set.legoId}`}
            alt={`Compare prices for set #${set.legoId}`}
          >
            Compare
          </Button> :
          null}
        <Button
          type={set.hasAffiliate ? 'primary' : 'default'}
          target="_blank"
          href={set.url}
          onClick={() => this.navToExternal(set)}
          alt={`Buy set #${set.legoId} from ${set.siteName}`}
        >Buy</Button>
        {set.affCode ? <span dangerouslySetInnerHTML={{__html: set.affCode}} /> : null}
      </div>
    );
  }
}

export default SetButtons;