import _ from 'lodash';
import React from 'react';
import ReactGA from 'react-ga';
import { AutoComplete, Button, Form, Input } from 'antd';
import { get } from '../../helpers/api';

const { Option } = AutoComplete;

class SearchBar extends React.Component {
  state = {};


  renderOption = (item) => {
    return (
      <Option key={item.id} text={item.name}>{item.name}</Option>
    );
  }

  getSets = (filter) => {
    get('/api/v1/sets/', {
      perPage: 15,
      orderBy: 'legoId',
      orderDirection: 'desc',
      filter,
    }).then((results) => {
      const sets = _.map(results.sets, ({ legoId, theme, name, year }) => {
        return { id: `${legoId}`, name: `${legoId} ${name}` };
      });
      this.setState({ sets });
    });
  }

  handleSearch = _.debounce((legoId) => {
    this.setState({ legoId });
    if (!legoId) { return; }

    ReactGA.event({
      category: 'Desktop',
      action: 'Search for sets',
      label: legoId,
    });

    const filter = `legoId startWith '${legoId}' or name like '${legoId}'`;
    this.getSets(filter);
  }, 50);

  handleSelect = (legoId) => {
    ReactGA.event({
      category: 'Desktop',
      action: 'Select search result',
      label: legoId,
    });
    window.location.href = `#/sets/legoId/${legoId || this.state.legoId}`;
  }

  handleChange = (legoId) => {
    this.setState({ legoId });
  }

  render () {
    return (
      <AutoComplete
        className="global-search"
        dataSource={_.map(this.state.sets, this.renderOption)}
        filterOption={false}
        onChange={this.handleChange}
        onSearch={this.handleSearch}
        onSelect={this.handleSelect}
      >
        <Input
          autoComplete="off"
          name="legoId"
          suffix={
            <Button
              type="primary"
              onClick={() => this.handleSelect()}
            ><i className="fa fa-search" aria-hidden="true"></i></Button>
          }
          placeholder="LEGO&reg; set number or name..."
        />
      </AutoComplete>
    );
  }
}

export default Form.create()(SearchBar);
