import request from 'superagent';
import { API_BASE_URL } from './constants';

export const get = (url, qs = {}) =>
  request
    .get(`${API_BASE_URL}${url}`)
    .query(qs)
    .type('json')
    .then((res) => {
      return res.body;
    })
    .catch((err) => console.log(err));
